.dynamicbackground-canvas {
  position: absolute;
  border-radius: 50%;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@media screen and (max-width: 900px) {
  .dynamicbackground-canvas {
    display: none;
  }
}
