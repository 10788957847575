$prefix: husky-main;

.#{$prefix}-container {
  height: 100vh;
  width: 100vw;
}

.#{$prefix}-contents-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #000000;
  overflow: hidden;
}

p, div {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

/* Remove blue area when tap on a component on mobile */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}