.lightframe-container {
  width: 100%;
  height: 100%;
}

.lightframe-light {
  animation: lightframe-light-animation 1.5s 6s infinite alternate;
  position: absolute;
  z-index: 1;
}

.lightframe-light-top, .lightframe-light-bottom {
  width: 100%;
  height: 1px;
}

.lightframe-light-left, .lightframe-light-right {
  width: 0;
  height: 100%;
}

.lightframe-light-top {
  top: 0;
}

.lightframe-light-bottom {
  bottom: 0;

}

.lightframe-light-left {
  left: 0;
}

.lightframe-light-right {
  right: 0;
}

.lightframe-light-animated-show {
  animation: lightframe-light-animation-show 1s ease-out forwards; 
}

.lightframe-light-animated-disappear {
  animation: lightframe-light-animation-disappear 1s ease-in forwards; 
}

@keyframes lightframe-light-animation {
  0% {
    -webkit-box-shadow: 0px 0px 50px 30px;
    -moz-box-shadow: 0px 0px 50px 30px;
    box-shadow: 0px 0px 50px 30px;
  }
  25% {
    -webkit-box-shadow: 0px 0px 50px 32px;
    -moz-box-shadow: 0px 0px 50px 32px;
    box-shadow: 0px 0px 50px 32px;
  }
  50% {
    -webkit-box-shadow: 0px 0px 65px 35px;
    -moz-box-shadow: 0px 0px 65px 35px;
    box-shadow: 0px 0px 65px 35px;
  }
  100% {
    -webkit-box-shadow: 0px 0px 70px 30px;
    -moz-box-shadow: 0px 0px 70px 30px;
    box-shadow: 0px 0px 70px 30px;
  }
}


@keyframes lightframe-light-animation-show {
  from {
    -webkit-box-shadow: 0px 0px 0px 00px;
    -moz-box-shadow: 0px 0px 0px 00px;
    box-shadow: 0px 0px 0px 00px;
  }
  to {
    -webkit-box-shadow: 0px 0px 50px 30px;
    -moz-box-shadow: 0px 0px 50px 30px;
    box-shadow: 0px 0px 50px 30px;
  }
}

@keyframes lightframe-light-animation-disappear {
  from {
    -webkit-box-shadow: 0px 0px 50px 30px;
    -moz-box-shadow: 0px 0px 50px 30px;
    box-shadow: 0px 0px 50px 30px;
  }
  to {
    -webkit-box-shadow: 0px 0px 0px 00px;
    -moz-box-shadow: 0px 0px 0px 00px;
    box-shadow: 0px 0px 0px 00px;
  }
}

@keyframes lightframe-light-animation-mobile {
  0% {
    -webkit-box-shadow: 0px 0px 36px 25px;
    -moz-box-shadow: 0px 0px 36px 25px;
    box-shadow: 0px 0px 36px 25px;
  }
  25% {
    -webkit-box-shadow: 0px 0px 36px 28px;
    -moz-box-shadow: 0px 0px 36px 28px;
    box-shadow: 0px 0px 36px 28px;
  }
  50% {
    -webkit-box-shadow: 0px 0px 45px 26px;
    -moz-box-shadow: 0px 0px 45px 26px;
    box-shadow: 0px 0px 45px 26px;
  }
  100% {
    -webkit-box-shadow: 0px 0px 36px 25px;
    -moz-box-shadow: 0px 0px 36px 25px;
    box-shadow: 0px 0px 36px 25px;
  }
}


@keyframes lightframe-light-animation-show-mobile {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px;
    -moz-box-shadow: 0px 0px 0px 0px;
    box-shadow: 0px 0px 0px 0px;
  }
  to {
    -webkit-box-shadow: 0px 0px 36px 25px;
    -moz-box-shadow: 0px 0px 36px 25px;
    box-shadow: 0px 0px 36px 25px;
  }
}

@keyframes lightframe-light-animation-disappear-mobile {
  from {
    -webkit-box-shadow: 0px 0px 36px 25px;
    -moz-box-shadow: 0px 0px 36px 25px;
    box-shadow: 0px 0px 36px 25px;
  }
  to {
    -webkit-box-shadow: 0px 0px 0px 00px;
    -moz-box-shadow: 0px 0px 0px 00px;
    box-shadow: 0px 0px 0px 00px;
  }
}

@media screen and (max-width: 900px) {

  .lightframe-light{
    animation: lightframe-light-animation-mobile 1.5s 6s infinite alternate;
  }

  .lightframe-light-animated-show {
    animation: lightframe-light-animation-show-mobile 1s ease-out forwards; 
  }

  .lightframe-light-animated-disappear {
    animation: lightframe-light-animation-disappear-mobile 1s ease-in forwards; 
  }

}