$prefix: husky-loading;

.#{$prefix}-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
}

.#{$prefix}-text {
    animation: #{$prefix}-text-animation-blink 1s infinite alternate ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50vw;
    top: 46vh;
    padding: 38px 36px;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    font-size: 1.95rem;
    background: #000000;
    color: rgba(60, 180, 250, 0.6);
}

.#{$prefix}-container-animated-disappear {
  animation: #{$prefix}-container-animation-disappear .8s linear forwards;
}

.#{$prefix}-tilt-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 900px) {
  .#{$prefix}-text {
    font-size: 1.6rem;
    transform: translateX(-50%) translateY(-50%);
  }
}

@keyframes #{$prefix}-text-animation-blink {
  from {
    color:  rgba(60, 180, 250, 0.1);
  }
  to {
    color:  rgba(60, 180, 250, 0.6);
  }
}

@keyframes #{$prefix}-container-animation-disappear {
  to {
    opacity: 0;
    visibility: hidden;
  }
}